<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备类型">
          <el-select v-model="searchForm.typeId" placeholder="请选择设备类型" size="small" clearable>
            <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content" v-loading="loading">
      <el-table :data="operationList" stripe style="width: 100%" height="100%">
        <el-table-column prop="unitName" label="所属单位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip width="120">
        </el-table-column>
        <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="describes" label="警情描述" show-overflow-tooltip width="300">
        </el-table-column>
        <el-table-column prop="address" label="报警点位置" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.updateTime|timeFormat}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="15" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      operationList: [],
      typeList: [],
      searchForm: {},
      loading: false
    }
  },
  created () {
    this.initTable()
    this.getTypeList()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      data.pageNo = this.currentPage
      data.pageSize = 15
      this.loading = true
      const { data: result } = await this.$axios.get('/equipmentRuntimeInfo/list', { params: data })
      if (result.code === 200) {
        this.operationList = result.data.result
        this.total = result.data.total
        this.loading = false
      } else {
        this.$message.error(result.msg)
      }
    },
    // 设备类型
    async getTypeList () {
      const { data: result } = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      } else {
        this.$message.error(result.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
